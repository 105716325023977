/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Link } from 'gatsby'
import device from '../lib/mediaQueries'

const randomPercentageInside = (maxValue = 50) => `${Math.random() * maxValue}%`
const randomPercentageOutside = () => {
  const randomValue = Math.random() * 100 + 100
  const randomSignedNumber = Math.random() >= 0.5 ? 1 : -1

  return `${randomValue * randomSignedNumber}%`
}
const randomScale = () => 1 + Math.random() - Math.random()

const Title = styled.p`
  font-size: 22px;
  font-weight: bold;
  color: ${(props) => props.scheme.main};
  margin: 0px;
  transition: color 0.2s ease-out;
  text-align: center;
`

const DifferenceObject = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: ${(props) => props.scheme.inverted};
  top: 110%;
  left: 110%;
  border-radius: 200px;
  mix-blend-mode: difference;
  pointer-events: none;
  transition: all 0.2s ease-out;
`

const Container = styled(Link)`
  width: 50%;
  min-height: 220px;

  ${device.tablet} {
    width: 33.33%;
    min-height: 300px;
  }
  /* ${device.laptop} {
    width: 25%;
  } */
  position: relative;
  box-sizing: border-box;
  padding: 20px;

  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) => props.scheme.background};
  transition: background-color 0.2s ease-out;
  z-index: 10;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  &:hover,
  &:active {
    background-color: ${(props) => props.scheme.background};

    ${Title} {
      color: ${(props) => props.scheme.main};
    }
  }
`

const ProjectAward = ({ scheme, title, path }) => {
  const [position, setPosition] = React.useState({
    top: randomPercentageInside(60),
    left: randomPercentageOutside(),
    scale: 1,
  })

  return (
    <Container
      scheme={scheme}
      to={path}
      onMouseEnter={() => {
        setPosition({
          top: randomPercentageInside(),
          left: randomPercentageInside(),
          scale: randomScale(),
        })
      }}
      onMouseLeave={() => {
        setPosition({
          top: randomPercentageInside(60),
          left: randomPercentageOutside(),
          scale: 1,
        })
      }}
    >
      <Title scheme={scheme}>{title}</Title>
      <DifferenceObject
        scheme={scheme}
        style={{
          top: position.top,
          left: position.left,
          transform: `scale(${position.scale}, ${position.scale})`,
        }}
      />
    </Container>
  )
}

ProjectAward.propTypes = {
  title: PropTypes.string.isRequired,
}

export default ProjectAward
