import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import DefaultSection from '../components/DefaultSection'
import Layout from '../components/Layout'
import ProjectPreview from '../components/ProjectPreview'
import Difference, { randomPercentage } from '../components/DifferenceObject'
import device from '../lib/mediaQueries'
import * as colors from '../lib/colors'

const move = keyframes`
    to {
      transform: translate3d(${randomPercentage(1000)}, ${randomPercentage(
  1000,
)},0) rotate(250deg);
    }
`

const ProjectSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  margin-top: 20px;
  padding: 0px;

  ${device.tablet} {
    padding: 8px;
  }

  &::after {
    content: '';
    flex-grow: auto;
  }
`

const DifferenceObject = styled(Difference)`
  width: 50px;
  height: 50px;
  animation: ${move} 120s linear alternate infinite;
`

const ProjectsPage = ({ data: { allMdx } }) => {
  const { edges: projects } = allMdx
  const scheme = colors.main
  return (
    <>
      <Layout theme={scheme} title="Work">
        <DefaultSection>
          <h1>A look back.</h1>
          Here's a bit of what I've done in the past, from consumer products to
          tools and experiments. I'm passionate about projects that solve{' '}
          <strong>hard problems</strong> in different ways.
        </DefaultSection>

        <ProjectSection>
          {projects.map(({ node: project }) => (
            <ProjectPreview
              scheme={colors[project.frontmatter.scheme]}
              path={project.frontmatter.path}
              title={project.frontmatter.name}
              description={project.frontmatter.title}
            />
          ))}
        </ProjectSection>
        <DifferenceObject maxLeft={40} maxTop={15} />
      </Layout>
    </>
  )
}

ProjectsPage.propTypes = {
  data: PropTypes.shape,
}

export default ProjectsPage

export const pageQuery = graphql`
  {
    allMdx(sort: { order: DESC, fields: frontmatter___ended }) {
      edges {
        node {
          id
          frontmatter {
            name
            title
            scheme
            path
          }
        }
      }
    }
  }
`
